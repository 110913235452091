import React from "react";
import './style.css'
import {Col, Row} from 'react-bootstrap'
const Footer = () => {
    return (
        <div className="bg-footer">
            <Row>
                <Col>
                    Министерство просвещения Российской Федерации
                    ФГБОУ ВО ШГПУ
                    E-mail: vuz@shgpi.edu.ru
                    Адрес: 641800, Курганской области,
                    г. Шадринск, ул. К. Либкнехта, 3.
                    Телефон: (35253)6-35-02
                </Col>
                <Col>


                    Министерство просвещения Российской Федерации
                    ФГБОУ ВО ШГПУ
                    E-mail: vuz@shgpi.edu.ru
                    Адрес: 641800, Курганской области,
                    г. Шадринск, ул. К. Либкнехта, 3.
                    Телефон: (35253)6-35-02

                    Контакты приемной комиссии
                    тел.: (8-352-53) 6–45–19
                    E–mail: pk@shgpi.edu.ru

                    Политика обработки и защиты персональных данных


                </Col>

                <Col>
                    Доступная среда
                    Противодействие коррупции
                    Противодействие терроризму
                    Памятка о действиях мошенников
                    Сведения о доходах, об имуществе и обязательствах имущественного характера руководителя и членов его семьи
                </Col>
            </Row>
        </div>
    )
};

export default Footer;